<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-content`"
        v-model="properties.content"
        outlined
        dense
        :label="$lang.labels.content"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="qr-content"
        @input="handleChange('content', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-select
        :key="`${step.id}-errorCorrectionLevel`"
        v-model="properties.errorCorrectionLevel"
        :items="formattedErrorCorrectionLevels"
        :label="$lang.labels.errorCorrectionLevel"
        outlined
        dense
        required
        :rules="[v => !!v || $lang.labels.required]"
        data-cy="qr-errorCorrectionLevel"
        :readonly="!canEdit"
        class="required-asterisk"
        @change="handleChange('errorCorrectionLevel', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-size`"
        v-model="properties.size"
        outlined
        dense
        :label="$lang.labels.size"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        type="number"
        data-cy="qr-size"
        @input="handleChange('size', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="qr-targetObject"
        @input="handleChange('targetObject', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'
import { definitions } from '@/utils/definitions'
import { castValueForStep } from '@/utils/helpers'

export default {
  name: 'QRStep',
  mixins: [StepMixin],
  data() {
    return {
      errorCorrectionLevels: definitions.QRStepProperties.properties.errorCorrectionLevel.enum
    }
  },
  computed: {
    formattedErrorCorrectionLevels() {
      return this.errorCorrectionLevels.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.errorCorrectionLevel) this.properties.errorCorrectionLevel = 'LOW'
  },
  methods: {
    handleChange(key, value) {
      this.$set(
        this.properties,
        key,
        castValueForStep(key, value, {
          shouldBeString: ['content']
        })
      )
    }
  }
}
</script>
